<template>

    <div>
        <b-card no-body class="mb-0">
            <div class="m-2">
                <b-row>
                    <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                        <label>Show</label>
                        <v-select v-model="perPage" :options="perPageOptions" :clearable="false"
                                  class="per-page-selector d-inline-block mx-50 ml-1"/>
                    </b-col>
                    <b-col cols="12" md="6">
                        <b-row class="d-flex align-items-center justify-content-end">
                            <b-col cols="12" md="4" class="mb-md-0 mb-2">
                                <v-select :options="statusOptions"
                                          :reduce="(option) => option.value"
                                          v-model="statusFilter" placeholder="Select Status" class="w-100"/>
                            </b-col>
                            <b-col cols="12" md="4" class="mb-md-0 mb-2">
                                <b-form-input v-model="searchQuery" class="d-inline-block mr-1"
                                              placeholder="Search..."/>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
            </div>
            <b-table ref="refCampaignListTable" class="position-relative" :items="fetchCampaigns" responsive
                     :table-class="'build-list-table table dataTable no-footer dtr-column'" striped
                     :fields="tableColumns"
                     primary-key="id" :sort-by.sync="sortBy" show-empty empty-text="No matching records found"
                     :sort-desc.sync="isSortDirDesc">
                <template #table-busy>
                    <div class="text-center my-2">
                        <b-spinner class="d-block mx-auto mb-50"></b-spinner>
                        <strong>Loading...</strong>
                    </div>
                </template>
                <!-- Column: id -->
                <template #cell(id)="data">
                    <span class="align-text-top text-capitalize">{{ data.item.id }}</span>
                </template>

                <!-- Column: Type -->
                <template #cell(campaign_type)="data">
          <span class="align-text-top text-capitalize">
            <social-icons :socialType="data.item.campaign_type" :size="'24'"></social-icons>
          </span>
                </template>

                <!-- Column: name -->
                <template #cell(campaign_name)="data">
                    <span class="align-text-top text-capitalize">{{ data.item.campaign_name }}</span>
                </template>

                <!-- Column: Status -->
                <template #cell(status)="data">
          <span class="align-text-top text-capitalize">
            	<campaign-detail-status :campaignData="data.item"></campaign-detail-status>
          </span>
                </template>

                <!-- Column: Creator -->
                <template #cell(user_id)="data">
                    <span class="align-text-top text-capitalize">{{ data.item.user ? data.item.user.name : '-' }}</span>
                </template>

                <!-- Column: Start time -->
                <template #cell(start_time)="data">
                    <span class="align-text-top text-capitalize">{{ dateFormat(data.item.start_time) }}</span>
                </template>

                <!-- Column: End time -->
                <template #cell(end_time)="data">
                    <span class="align-text-top text-capitalize">{{ dateFormat(data.item.end_time) }}</span>
                </template>

                <!--Column: Actions -->
                <template #cell(actions)="data">
                    <b-link class="mr-1" v-if="$can('read', 'campaignList')"
                            :to="{ name: 'campaign-detail', params: { campaign: data.item.id } }"
                            v-b-tooltip.hover.top="'Detail Campaign'">
                        <feather-icon icon="EyeIcon" size="18"/>
                    </b-link>

                    <b-link class="mr-1" v-if="$can('read', 'campaignDelete')" v-b-tooltip.hover.top="'Delete Campaign'"
                            v-on:click="confirmDelete(data.item.id)">
                        <feather-icon icon="TrashIcon" size="18"/>
                    </b-link>
                </template>
            </b-table>

            <div class="mx-2 mb-2">
                <b-row>
                    <b-col cols="12" sm="6"
                           class="d-flex align-items-center justify-content-center justify-content-sm-start">
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }}
              entries</span>
                    </b-col>
                    <b-col cols="12" sm="6"
                           class="d-flex align-items-center justify-content-center justify-content-sm-end">
                        <b-pagination v-model="currentPage" :total-rows="totalCampaigns" :per-page="perPage"
                                      first-number
                                      last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item"
                                      next-class="next-item">
                            <template #prev-text>
                                <feather-icon icon="ChevronLeftIcon" size="18"/>
                            </template>
                            <template #next-text>
                                <feather-icon icon="ChevronRightIcon" size="18"/>
                            </template>
                        </b-pagination>
                    </b-col>
                </b-row>
            </div>
        </b-card>
    </div>
</template>

<script>
import {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    VBTooltip,
    BSpinner
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import useCampaignApi from '@/composables/useCampaignApi'
import moment from 'moment'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import SocialIcons from '@/components/SocialIcons.vue'
import CampaignDetailStatus from '@/components/CampaignDetailStatus.vue'

export default {
    components: {
        SocialIcons,
        BSpinner,
        BCard,
        BRow,
        BCol,
        BFormInput,
        BButton,
        BTable,
        BMedia,
        BAvatar,
        BLink,
        BBadge,
        BDropdown,
        BDropdownItem,
        BPagination,
        vSelect,
        CampaignDetailStatus,
    },
    directives: {
        'b-tooltip': VBTooltip,
        Ripple,
    },
    setup() {
        const userData = JSON.parse(localStorage.getItem('userData'))
        const {
            fetchCampaigns,
            tableColumns,
            perPage,
            currentPage,
            totalCampaigns,
            dataMeta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            refCampaignListTable,
            refetchData,
            statusFilter,
            statusOptions,
        } = useCampaignApi()

        return {
            fetchCampaigns,
            tableColumns,
            perPage,
            currentPage,
            totalCampaigns,
            dataMeta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            refCampaignListTable,
            refetchData,
            statusOptions,
            statusFilter,
            userData
        }

    },

    methods: {
        dateFormat(date) {
            return moment(date).format('YYYY-MM-DD HH:mm')
        },
        confirmDelete(id) {
            this.$swal.fire({
                title: 'Are you sure?',
                text: "Are you sure you want to delete the campaign?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, delete!',
                customClass: {
                    confirmButton: 'btn btn-primary mr-1',
                    cancelButton: 'btn btn-outline-primary ms-1'
                },
                buttonsStyling: false
            }).then(result => {
                if (result.value) {
                    this.$store.dispatch('campaignStore/deleteCampaign', {
                        id: id
                    }).then(() => {
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: 'Successfully deleted!',
                                icon: 'CheckIcon',
                                variant: 'success',
                            },
                        })
                    }).then(() => {
                        this.refetchData()
                    })
                }
            })
        }
    }
}

</script>

<style lang="scss" scoped>
.per-page-selector {
    width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
