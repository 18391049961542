// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

// eslint-disable-next-line object-curly-newline
import { ref, computed, watch } from '@vue/composition-api'
import store from '@/store'
import router from '@/router'
export default function useCampaignApi() {
  // Use toast
  const toast = useToast()
  // Table Handlers
  const refCampaignListTable = ref(null)
  const perPage = ref(localStorage.getItem('campaignPerPage')??10)
  const totalCampaigns = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10,15, 20, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const statusFilter = ref(null)
  const isLoading = ref(true)
  const campaignData = ref(null)
  const previewLoading = ref(false)
  const previewDetail = ref(null)
  const reviewOk = ref(null)
  const organizationOptions = ref(null)
  const addAccountsOptions = ref(null)

  const tableColumns = [
    { key: 'id', sortable: true  },
    { key: 'campaign_type', sortable: true,label:'Type'},
    { key: 'campaign_name', sortable: true  },
    { key: 'user_id', sortable: true,label:'Creator' },
    { key: 'status', sortable: true },
    { key: 'start_time', sortable: true},
    { key: 'end_time', sortable:true},
    { key: 'actions' },
  ]
  const statusOptions = [
    { label: 'Waiting', value: 'Waiting' },
	{ label: 'In Progress', value: 'inprogress' },
    { label: 'Live', value: 'Live' },
    { label: 'Completed', value: 'Completed' },
	{ label: 'Failed', value: 'Failed' },

  ]

  const dataMeta = computed(() => {
    const localItemsCount = refCampaignListTable.value ? refCampaignListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalCampaigns.value,
    }
  })

  const refetchData = () => {
    localStorage.setItem('campaignPerPage',perPage.value)
    refCampaignListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, statusFilter], () => {
    refetchData()
  })


  // ------------------------------------------------
  // Get Campaign List
  // ------------------------------------------------
  const fetchCampaigns = (ctx, callback) => {
    let sort = isSortDirDesc.value ? `-${sortBy.value}` : sortBy.value
    store
      .dispatch('campaignStore/fetchCampaigns', {
        sort: sort,
        page: currentPage.value,
        perPage: perPage.value,
        q: searchQuery.value,
        status: statusFilter.value,
        pagination:true
      })
      .then(response => {
        const { data, total } = response.data
        callback(data)
        totalCampaigns.value = response.data.pagination.total
      })
      .catch(error => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text:'Oops! Something went wrong!',
          },
        })
      })
  }

  const fetchCampaign = (ctx,callback)=>{
    store
      .dispatch('campaignStore/fetchCampaign',  { id: router.currentRoute.params.campaign })
        .then(response => {
          const {data} = response.data
          campaignData.value =  data
          isLoading.value = false
        })
        .catch(error => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text:'Oops! Something went wrong!',
            },
          })
          router.push('/campaign/list');
        })
  }

  const previewAppstore = (app_id)=>{
    previewLoading.value = true
    previewDetail.value = null
    reviewOk.value = null
    if(app_id){
      store
        .dispatch('campaignStore/previewAppstore',  { app_id: app_id })
          .then(response => {
            const {data} = response
            previewDetail.value = data
            previewLoading.value = false
            reviewOk.value='OK'
          })
          .catch(error => {
            toast({
              component: ToastificationContent,
              props: {
                title: 'Error',
                text:'Please enter a correct id and click the preview button.',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
            previewLoading.value = false
            reviewOk.value=null

          })
    }else{
      previewLoading.value = false
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error',
          text:'Please enter a correct id and click the preview button.',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    }
  }

  	const fetchOrganizations = (ctx, callback) => {
		store
			.dispatch('settingStore/fetchAltSetting', {title:'Organizations',type:'snapchat'})
			.then(response => {
				const {data} = response.data
				const jsonData = JSON.parse(data)
				organizationOptions.value = jsonData.map(item=>({value:item.organization.id,label:item.organization.name}))
			}).catch(error => {
				console.log(error)
			})
  	}

  	const fetchAdAccounts= (organization) => {
		store
			.dispatch('settingStore/fetchAltSetting', {title:'Ad Accounts',type:'snapchat'})
			.then(response => {
				const {data} = response.data
				const jsonData = JSON.parse(data)
				const settings = jsonData[0].filter((item)=> item.adaccount.organization_id === organization)
				addAccountsOptions.value = settings.map(item=>({value:item.adaccount.id,label:item.adaccount.name}))
			}).catch(error => {
				console.log(error)
			})
	}

	const organizationAdAccounts= (organization) => {
		if(organization){
			fetchAdAccounts(organization)
		}else{
			addAccountsOptions.value = ''
		}
	}


  return {
    tableColumns,
    perPage,
    currentPage,
    totalCampaigns,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refCampaignListTable,
    refetchData,
    fetchCampaigns,
    statusOptions,
    statusFilter,
    fetchCampaign,
    campaignData,
    isLoading,
    previewAppstore,
    previewDetail,
    previewLoading,
    reviewOk,
	fetchOrganizations,
	organizationOptions,
	fetchAdAccounts,
	addAccountsOptions,
	organizationAdAccounts
  }
}
