<template>
    <h6 class="text-capitalize">
        <b-badge v-if="campaignData.status=== 'failed'" variant="light-danger">
            <feather-icon icon="XIcon" class="mr-25" /><span>{{ campaignData.status}}</span>
        </b-badge>
        <b-badge v-else-if="campaignData.status === 'inprogress'" variant="light-info">
            <feather-icon icon="ClockIcon" class="mr-25" />
            <span>{{ campaignData.status}}</span>
        </b-badge>
        <b-badge v-else-if="campaignData.status === 'waiting'" variant="light-warning">
            <feather-icon icon="LoaderIcon" class="mr-25" />
            <span>{{ campaignData.status}}</span>
        </b-badge>
        <b-badge v-else-if="campaignData.status === 'live'" variant="light-success">
            <feather-icon icon="BarChart2Icon" class="mr-25" />
            <span>{{ campaignData.status}}</span>
        </b-badge>
        <b-badge v-else-if="campaignData.status === 'completed'" variant="light-primary">
            <feather-icon icon="CheckCircleIcon" class="mr-25" />
            <span>{{ campaignData.status}}</span>
        </b-badge>
    </h6>
</template>
<script>
import {
	BBadge,
} from 'bootstrap-vue'
export default{
     components: {
       BBadge
    },
    props:{
        campaignData:{}
    }

}
</script>
